import * as Bowser from "bowser";

export function getBrowserName(ua) {
    const browser = Bowser.getParser(ua);
    return browser.getBrowser().name;
}

export function getOsName(ua) {
    const parsedResult = Bowser.parse(ua);
    return parsedResult.os.name;
}