import { connect } from 'react-redux';
import Header from '../../../components/paperbase/Header';
import { changeActiveTabItem } from '../dashboardSlice';

const mapStateToProps = (state, ownProps) => ({
    categories: state.dashboard.categories,
    activecatid: state.dashboard.activeCatId,
    activechildid: state.dashboard.activeChildId,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeactivetabitem: (tabItem) => dispatch(changeActiveTabItem(tabItem))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);