import React, { useEffect } from 'react';
import './Overlay.css';

export function OverlayWebWidget(props) {

    let trackerConfig = JSON.parse(props.tc);
    
    useEffect(() => {
        document.getElementById('overlaypreview').style.background = trackerConfig.color;
        document.getElementById('overlaypreview').style.backgroundColor = trackerConfig.color;
        document.getElementById('overlaypreview').textContent = trackerConfig.tooltip;
    });

    return (
        <>
        <div class="devmakell-usertap-overlay">
        </div>
        <div id="overlaypreview" class="devmakell-usertap-message">
            PreviewText
        </div>
        </>
    );
}