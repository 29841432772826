import React from 'react';
import AddTrackerConnected from '../../features/tracker/components/AddTrackerConnected';
import ListTrackersConnected from '../../features/tracker/components/ListTrackersConnected';
import OneTrackerConnected from '../../features/tracker/components/OneTrackerConnected';
import PingListConnected from '../../features/tracker/components/PingListConnected';
import ReportTrackerConnected from '../../features/tracker/components/ReportTrackerConnected';
import TrackerPingsConnected from '../../features/tracker/components/TrackerPingsConnected';
import UpdateTrackerConnected from '../../features/tracker/components/UpdateTrackerConnected';
import UserInfoConnected from '../../features/auth/components/UserInfoConnected';
import debugLog from '../../services/debug/log';
import NotImplemented from '../dashboard/NotImplemented';

function Content(props) {
  let caseKey = props.activeCatId + props.activeChildId + props.activeTabItem;

  debugLog.debug("Dashboard:: Content : Key : %s", caseKey);

  switch(caseKey) {
    case 'FeederExploreList':
      return (
        <ListTrackersConnected />
      );
    case 'FeederExploreAdd':
      return (
        <AddTrackerConnected />
      );
    case 'FeederExploreCurrent':
      return (
        <OneTrackerConnected />
      );
    case 'FeederPingsAll':
      return (
        <PingListConnected />
      );
    case 'FeederPingsSingle':
      return (
        <TrackerPingsConnected />
      );
    case 'FeederExploreEdit':
      return (
        <UpdateTrackerConnected />
      );
    case 'FeederReportsSummary':
      return (
        <ReportTrackerConnected />
      );
    case 'AccountUser ProfileProfile':
      return (
        <UserInfoConnected />
      );
    default:
      return (
        <NotImplemented />
      );
  }

}

export default Content;
