import { connect } from 'react-redux';
import UpdateTracker from '../../../components/tracker/UpdateTracker';
import { updateAsync } from '../trackerApiSlice';
import { selectTrackerEntityById } from '../trackerEntitySlice';

const mapStateToProps = (state, ownProps) => ({
    curTrackerId: state.tracker.curTrackerId,
    trackerinfo: selectTrackerEntityById(state, state.tracker.curTrackerId)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateAsync: (values) => dispatch(updateAsync(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTracker);