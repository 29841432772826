import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login, register } from '../../services/api/Auth';
import debugLog from '../../services/debug/log';
import { setAccessToken, setRefreshToken, setNextRefresh, getAccessToken, getRefreshToken, getNextRefresh } from '../../services/localstorage/Auth';

const initialState = {
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    tokenType: null,
    expiryDuration: 900000,
    isLoggedIn: getNextRefresh() > Date.now(),
    registrationSuccess: false,
    loginFailed: false,
    curUserId: null
};

export const registerAsync = createAsyncThunk(
    'auth/register',
    async(values) => {
        try {
            const response = await register(values.email, values.email, values.password);
            debugLog.debug("registerAsync :: response: [%o]", response);
            return response;
        } catch (err) {
            debugLog.debug("registerAsync :: Error: [%o]", err);
            return err;
        }
    }
)

export const loginAsync = createAsyncThunk(
    'auth/login',
    async(values) => {
        try {
            const response = await login(values.email, values.password)
            return response;
        } catch (err) {
            debugLog.debug("loginAsync :: Error: [%o]", err);
            return err;
        }
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.curUserId = null;
            state.accessToken = null;
            state.refreshToken = null;
        },
        setUserId: (state, action) => {
            state.curUserId = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(registerAsync.pending, (state) => {
        })
        .addCase(registerAsync.fulfilled, (state, action) => {
            if (action.payload.error) {
                state.registrationSuccess = false;
            } else {
                if (action.payload.success) {
                    state.registrationSuccess = true;
                } else {
                    state.registrationSuccess = false;
                }
            }
        })
        .addCase(loginAsync.pending, (state) => {
            state.loginFailed = false;
            state.accessToken = null;
            state.refreshToken = null;
        })
        .addCase(loginAsync.fulfilled, (state, action) => {
            if (action.payload.error) {
                state.isLoggedIn = false;
                state.loginFailed = true;
            } else {
                if (action.payload.success !== undefined && action.payload.success === false) {
                    state.isLoggedIn = true;
                } else {
                    state.isLoggedIn = true;
                    state.accessToken = action.payload.accessToken;
                    state.refreshToken = action.payload.refreshToken;
                    setAccessToken(action.payload.accessToken);
                    setRefreshToken(action.payload.refreshToken);
                    setNextRefresh(Date.now() + (1000*3600));
                }
                state.loginFailed = false;
            }
        })
        .addCase(loginAsync.rejected, (state, action) => {
            state.loginFailed = true;
        });
    }
});

export const { logout, setUserId } = authSlice.actions;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectRegistrationSuccess = (state) => state.auth.registrationSuccess;
export const selectLoginFailed = (state) => state.auth.loginFailed;
export default authSlice.reducer;