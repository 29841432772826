import { postData } from "./Api";
import uuidv4 from '../helpers/uuid';

const API_AUTH_REGISTER = '/api/auth/register';
const API_AUTH_LOGIN = '/api/auth/login';

export async function register(username, email, password) {
    return postData(
        API_AUTH_REGISTER,
        {
            "email": email,
            "password": password,
            "username": username
        });
}

export async function login(username, password) {
    return postData(
        API_AUTH_LOGIN,
        {
            "deviceInfo": {
                "deviceType": "Browser",
                "notificationToken": uuidv4(),
                "deviceId": uuidv4(),
                "isRefreshActive": true
            },
            "email": username,
            "password": password,
            "username": username
        }
    );
}