import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { listTrackerPings } from '../../services/api/Tracker';
import { trackerPingFetchedMany } from './trackerPingEntitySlice';

const initialState = {
    // listTrackerPingsAsync
    listTrackerPingsAsyncLoading: false
}

export const listTrackerPingsAsync = createAsyncThunk(
    'trackerpingapi/listtrackerpings',
    async(values, { dispatch }) => {
        try {
            listTrackerPings(values.trackerId)
            .then( responseJson => {
                dispatch(trackerPingFetchedMany(responseJson));
                return Promise.resolve(responseJson);
            })
            .catch( errText => {
                return Promise.reject(errText);
            })
        } catch (err) {
            return err;
        }
    }
)

export const trackerPingApiSlice = createSlice({
    name: 'trackerpingapi',
    initialState: initialState,
    extraReducers: (builder) => {
        builder
        .addCase(listTrackerPingsAsync.pending, (state) => {
            state.listTrackerPingsAsyncLoading = true;
        })
        .addCase(listTrackerPingsAsync.fulfilled, (state, action) => {
            state.listTrackerPingsAsyncLoading = false;
        })
        .addCase(listTrackerPingsAsync.rejected, (state, action) => {
            state.listTrackerPingsAsyncLoading = false;
        })
    }
});

export default trackerPingApiSlice.reducer;