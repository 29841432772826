import { connect } from 'react-redux';
import TrackerPings from '../../../components/tracker/TrackerPings';
import { listTrackerPingsAsync } from '../trackerPingApiSlice';
import { selectTrackerPingEntityAll } from '../trackerPingEntitySlice';

function getTrackerPings(trackerId, allTrackerPings) {
    let retVal = [];
    if (trackerId === null || trackerId === undefined) {
        return retVal;
    }
    allTrackerPings.forEach(element => {
        if (element.trackerId === trackerId) {
            retVal.push(element);
        }
    });
    return retVal;
}

const mapStateToProps = (state, ownProps) => ({
    curTrackerId: state.tracker.curTrackerId,
    trackerPings: getTrackerPings(state.tracker.curTrackerId, selectTrackerPingEntityAll(state))
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    allTrackerPingsAsync: (trackerId) => dispatch(listTrackerPingsAsync(trackerId))
})

export default connect(mapStateToProps, mapDispatchToProps)(TrackerPings);