import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Snackbar from '../components/Snackbar';
import { Field, Form } from 'react-final-form';
import RFTextField from '../form/RFTextField';
import FormButton from '../form/FormButton';
import { joinWaitlist } from '../../../../services/api/workers';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: 'flex',
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.warning.main,
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
    marginTop: theme.spacing(1),
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
  },
  imagesWrapper: {
    position: 'relative',
  },
  imageDots: {
    position: 'absolute',
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: '100%',
    background: 'url(/static/img/productCTAImageDots.png)',
  },
  image: {
    position: 'absolute',
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 600,
  },
});

function ProductCTA(props) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);

  const validate = (values) => {
    const errors = {};
    
    if (!values.email) {
      errors.email = "Email is Required";
    }
    
   return errors;
  };

  const handleSubmit = async (values) => {
    await joinWaitlist(values);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
          
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                "email": "Your Email"
              }}
              validate={validate}
              subscription={{ submitting: true, pristine: true }}
              render={
                ({ handleSubmit, form, submitting, pristine, values }) => (
                  <>
                  <form onSubmit={handleSubmit} className={classes.cardContent} noValidate>
                  <Typography variant="h2" component="h2" gutterBottom>
                    Get the latest updates
                  </Typography>
                  <Typography variant="h5">
                    Be the first to know when we move out of beta and release new features, like this email picker !
                  </Typography>
                    <Field
                      autoComplete="email"
                      component={RFTextField}
                      disabled={submitting}
                      fullWidth
                      label="Email"
                      margin="normal"
                      name="email"
                      required
                    />
                    <FormButton
                      className={classes.button}
                      disabled={submitting || pristine}
                      color="secondary"
                      fullWidth
                    >
                      {submitting ? 'In progress…' : 'Join The Waitlist'}
                    </FormButton>
                  </form>
                  </>
                )
              }
              />
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden mdDown>
            <div className={classes.imageDots} />
            <img
              src="/static/img/photo-1577563908411-5077b6dc7624.webp"
              alt="call to action"
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        onClose={handleClose}
        message="Thanks For Joining the Waitlist !"
      />
    </Container>
  );
}

ProductCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCTA);
