import withRoot from '../app/withRoot';
// --- Post bootstrap -----
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';
import Link from '@material-ui/core/Link';
import { Field, Form } from 'react-final-form';
import Typography from '../components/onepirate/modules/components/Typography';
import AppFooter from '../components/onepirate/modules/views/AppFooter';
import AppAppBar from '../components/onepirate/modules/views/AppAppBar';
import AppForm from '../components/onepirate/modules/views/AppForm';
import RFTextField from '../components/onepirate/modules/form/RFTextField';
import FormButton from '../components/onepirate/modules/form/FormButton';
import { registerAsync, selectIsLoggedIn, selectRegistrationSuccess } from '../features/auth/authSlice';
import debugLog from '../services/debug/log';
import { Link as HistoryLink } from "react-router-dom";
import { USER_TAP_BETA } from '../app/config';
import JoinWaitlist from '../components/forms/JoinWaitlist';
import { validateEmail } from '../components/onepirate/modules/form/validation';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

function SignUp() {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const isRegisterSuccess = useSelector(selectRegistrationSuccess);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {  
    debugLog.debug("SignUp :: doRedirect : %d", isRegisterSuccess);  
    if (isRegisterSuccess) {
      history.push('/user/signin');
    }
    if (isLoggedIn) {
      history.push('/');
    }
  });

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is Required";
    }
    if (!validateEmail(values.email)) {
      errors.email = "Email is Invalid";
    }
    if (!values.password) {
      errors.password = "Password is Required";
    }
   return errors;
  };

  const onSubmit = async (values) => {
    debugLog.debug("SignUp :: Form Values : [%o]", values);
    dispatch(registerAsync(values));
  };

  return (
    <React.Fragment>
      <AppAppBar />
      {USER_TAP_BETA ? 
      <AppForm>
        <Typography variant="h3" gutterBottom marked="center" align="center">
            Gleaner is in Beta
        </Typography>
        <Typography variant="h3" gutterBottom marked="center" align="center">
            Join Waitlist
        </Typography>
        <br /><br /><br />
        <JoinWaitlist />
      </AppForm>
      
      :
      
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign Up
          </Typography>
          <Typography variant="body2" align="center">
            <Link component={HistoryLink} to="/user/signin" underline="always">
              Already have an account?
            </Link>
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            "email": "Your Email"
          }}
          validate={validate}
          subscription={{ submitting: true, pristine: true }}
          render={
            ({ handleSubmit, form, submitting, pristine, values }) => (
              <>
              <form onSubmit={handleSubmit} className={classes.form} noValidate>
                <Field
                  autoComplete="email"
                  component={RFTextField}
                  disabled={submitting}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  required
                />
                <Field
                  fullWidth
                  component={RFTextField}
                  disabled={submitting}
                  required
                  name="password"
                  autoComplete="current-password"
                  label="Password"
                  type="password"
                  margin="normal"
                />
                <FormButton
                  className={classes.button}
                  disabled={submitting || pristine}
                  color="secondary"
                  fullWidth
                >
                  {submitting ? 'In progress…' : 'Sign Up'}
                </FormButton>
              </form>
              </>
            )
          } 
        />
      </AppForm>
      }
      
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(SignUp);
