
import { getNextRefresh } from "../services/localstorage/Auth";
/**
 * Refresh JWT Token if needed
 */
export const refreshTokenMiddleware = store => next => action => {
    let nextRefresh = getNextRefresh();
    if (nextRefresh !== undefined && nextRefresh !== null) {
        if (nextRefresh < Date.now()) {
            //console.log("refreshTokenMiddleware :: Time to refresh the token");
        }
    }
    return next(action)
}