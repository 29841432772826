import withRoot from '../app/withRoot';
// --- Post bootstrap -----
import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import makeStyles from '@material-ui/styles/makeStyles';
import Link from '@material-ui/core/Link';
import Typography from '../components/onepirate/modules/components/Typography';
import AppFooter from '../components/onepirate/modules/views/AppFooter';
import AppAppBar from '../components/onepirate/modules/views/AppAppBar';
import AppForm from '../components/onepirate/modules/views/AppForm';
import RFTextField from '../components/onepirate/modules/form/RFTextField';
import FormButton from '../components/onepirate/modules/form/FormButton';
import { loginAsync, selectIsLoggedIn, selectLoginFailed } from '../features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import debugLog from '../services/debug/log';
import { Link as HistoryLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

function SignIn() {

  const classes = useStyles();
  const history = useHistory();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const loginFailed = useSelector(selectLoginFailed);
  const dispatch = useDispatch();

  useEffect(() => {
    
    if (isLoggedIn) {
      history.push('/');
    }

    if (loginFailed) {
      history.push('/user/signin/');
    }

  })

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is Required";
    }
    if (!values.password) {
      errors.password = "Password is Required";
    }
    return errors;
  };

  const onSubmit = async (values) => {
    debugLog.debug("SignIn :: Form values : [%o]", values);
    dispatch(loginAsync(values));
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign In
          </Typography>
          <Typography variant="body2" align="center">
            {'Not a member yet? '}
            <Link component={HistoryLink} to="/user/signup" align="center" underline="always">
              Sign Up here
            </Link>
          </Typography>
        </React.Fragment>
        <Form 
          onSubmit={onSubmit}
          subscription={{ submitting: true, pristine: true }}
          validate={validate}
          initialValues={{
            email: "",
            password: ""
          }}
          render={
            ({ handleSubmit, form, submitting, pristine, values }) => (
              <>
              <form onSubmit={
                async (...args) => {
                  await handleSubmit(...args);
                  form.reset();
                }
              } className={classes.form} noValidate>
                <Field
                  autoComplete="email"
                  autoFocus
                  component={RFTextField}
                  disabled={submitting}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  required
                  size="large"
                />
                <Field
                  fullWidth
                  size="large"
                  component={RFTextField}
                  disabled={submitting}
                  required
                  name="password"
                  autoComplete="current-password"
                  label="Password"
                  type="password"
                  margin="normal"
                />
                <FormButton
                    className={classes.button}
                  disabled={submitting || pristine }
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  {submitting ? 'In progress…' : 'Sign In'}
                </FormButton>
                
              </form>
              </>
            )
          }
        />
        <Typography align="center">
          <Link underline="always" component={HistoryLink} to="/user/forgotpass">
            Forgot password?
          </Link>
        </Typography>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(SignIn);
