import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    curTrackerId: null,
}

export const trackerSlice = createSlice({
    name: 'tracker',
    initialState,
    reducers: {
        setCurTracker: (state, action) => {
            state.curTrackerId = action.payload;
        }
    }
})

export const { setCurTracker } = trackerSlice.actions;
export default trackerSlice.reducer;