import React, { useEffect } from 'react';
import './FiveStar.css';
import '../WebWidgets.css';

export function FiveStarWebWidget(props) {

    let trackerConfig = JSON.parse(props.tc);
    
    useEffect(() => {
        let textElem = document.getElementsByClassName('devmakeall-usertap-fivestar-text')[0];
        textElem.innerHTML = trackerConfig.tooltip;
        let unicodeElem = document.getElementsByClassName('devmakeall-usertap-fivestar')[0];
        unicodeElem.style.color = trackerConfig.color;
    });

    return (
        <>
        <div class="devmakell-usertap-webwidgets-wrapper">
            <div class="devmakeall-usertap-fivestar-panel">
                <div class="devmakeall-usertap-fivestar">
                    <span class="devmakeall-usertap-fivestar-one">★</span>
                    <span class="devmakeall-usertap-fivestar-two">★</span>
                    <span class="devmakeall-usertap-fivestar-three">★</span>
                    <span class="devmakeall-usertap-fivestar-four">★</span>
                    <span class="devmakeall-usertap-fivestar-five">★</span>
                    <span class="devmakeall-usertap-fivestar-text">Rate Your Experience</span>
                </div>
            </div>
        </div>
        </>
    );
}