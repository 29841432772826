import {  createEntityAdapter,  createSlice } from '@reduxjs/toolkit';

const userEntityAdapter = createEntityAdapter({
    selectId: (user) => user.userId,
    sortComparer: (a, b) => a.userName.localeCompare(b.userName),
});

export const userEntitySlice = createSlice({
    name: 'userentity',
    initialState: userEntityAdapter.getInitialState({}),
    reducers: {
        userFetched: userEntityAdapter.setOne,
        userUpdated: userEntityAdapter.updateOne
    }
});

const userEntitySelectors = userEntityAdapter.getSelectors((state) => state.userentity);
export const selectUserEntityById = (state, id) => userEntitySelectors.selectById(state, id);
export const selectUserEntityCount = (state) => userEntitySelectors.selectTotal(state);
export const selectUserEntityAll = (state) => userEntitySelectors.selectAll(state);

export const { userFetched, userUpdated }  = userEntitySlice.actions;

export default userEntitySlice.reducer;