import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigatorConnected from '../../features/dashboard/components/NavigatorConnected';
import HeaderConnected from '../../features/dashboard/components/HeaderConnected';
import ContentConnected from '../../features/dashboard/components/ContentConnected';
import { USER_TAP_DOMAIN } from '../../app/strings';

/**
 * PlaceHolder Footer for paperbase dashboard
 * It is not necessary or important so no need to make 
 * it generic and move elsewhere
 * @returns 
 */
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        {USER_TAP_DOMAIN}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

/**
 * Width of sidebar in pixels
 */
const drawerWidth = 256;

// main content and footer color: (grey) #eaeff1

const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
});

/**
 * Contains Three Component
 * - Navigator: sidebar
 * (Sidebar remains static but shows current active task)
 * - The header: (with sidebar select specific appbar)
 * (Changes appbar based on what is selected in sidebar and what tab is selected in appbar)
 * - Content:
 * (Rest of the real estate for current content)
 * @param {*} props 
 * @returns 
 */
function Paperbase(props) {
  
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
      <div className={classes.root}>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <NavigatorConnected
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <NavigatorConnected PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </nav>
        <div className={classes.app}>
          <HeaderConnected onDrawerToggle={handleDrawerToggle} />
          <main className={classes.main}>
            <ContentConnected />
          </main>
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>
  );
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Paperbase);
