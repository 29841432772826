import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import './ProductValues.css';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  cardroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(15),
    minWidth: 960,
    minHeight: 540
  },
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      
      <Container className={classes.container}>

        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          Configure in Minutes
        </Typography>
        (hover on video to see our widget in action) <br /><br />
        <Card className={classes.cardroot + " devmakeall-usertap-tooltip-demo"}  variant="outlined">
          <CardContent>
            <CardMedia component='iframe' width="960" height="540" src="https://www.youtube.com/embed/H1CwSwMHOUU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></CardMedia>
          </CardContent>
          <div class="devmakeall-usertap-tooltiptext-demo">
            <div class="chatbubble">Click here if the video was helpful ?</div>
          </div>
        </Card>
        
        {/* <img
          src="/static/img/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        /> */}
        <div>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/img/productValues1.svg"
                alt="suitcase"
              />
              <Typography variant="h6" className={classes.title}>
                Minimalist
              </Typography>
              <Typography variant="h5">
              Get user to convey their feedback in least amount of clicks
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/img/productValues2.svg"
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                Customisable
              </Typography>
              <Typography variant="h5">
              Ask anything, anywhere in anyway you want
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src="/static/img/productValues3.svg"
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Cost Effective
              </Typography>
              <Typography variant="h5">
              Pay by the volume and for what you use
              </Typography>
            </div>
          </Grid>
        </Grid>
        </div>
        
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);

//<div class='devmakeall-video-container'><iframe src='https://www.youtube.com/embed/QILiHiTD3uc' frameborder='0' allowfullscreen></iframe></div>