import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import NoTrackerSelected from '../dashboard/NoTrackerSelected';

const styles = (theme) => ({
  form: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
    padding: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});


function TrackerInfo(props) {
  const { classes, curTrackerId, trackerinfo } = props;

  if (curTrackerId === null || 
    trackerinfo === null ||
    curTrackerId === undefined ||
    trackerinfo === undefined) {

      return (<NoTrackerSelected />);

  }
  
  let trackerConfig = JSON.parse(trackerinfo.trackerConfig);

  return (
    <Paper className={classes.paper}>
      
      <h3>{trackerinfo.trackerName}</h3>
      <p>Tracker Type: <b>{trackerinfo.trackerType}</b></p>
      
      <Grid container spacing={1} border={1} borderRadius={1} borderColor="secondary.main">
        <Grid item xs={12} sm={4}>Color</Grid>
        <Grid item xs={12} sm={4}>{trackerConfig.color}</Grid>
        <Grid item xs={12} sm={4}></Grid>

        <Grid item xs={12} sm={4}>Shape</Grid>
        <Grid item xs={12} sm={4}>{trackerConfig.shape}</Grid>
        <Grid item xs={12} sm={4}></Grid>

        <Grid item xs={12} sm={4}>backgroundImage</Grid>
        <Grid item xs={12} sm={4}>{trackerConfig.backgroundImage}</Grid>
        <Grid item xs={12} sm={4}></Grid>

        <Grid item xs={12} sm={4}>TooTip</Grid>
        <Grid item xs={12} sm={4}>{trackerConfig.tooltip}</Grid>
        <Grid item xs={12} sm={4}></Grid>

        <Grid item xs={12} sm={4}>OnClick</Grid>
        <Grid item xs={12} sm={4}>{trackerConfig.onClick}</Grid>
        <Grid item xs={12} sm={4}></Grid>

        <Grid item xs={12} sm={4}>AnchorId</Grid>
        <Grid item xs={12} sm={4}>{trackerConfig.anchorid === undefined ? 'undefined' : trackerConfig.anchorid.toString()}</Grid>
        <Grid item xs={12} sm={4}></Grid>

        <Grid item xs={12} sm={4}>Deploy</Grid>
        <Grid item xs={12} sm={4}>{trackerConfig.deploy === undefined ? 'false' : trackerConfig.deploy.toString()}</Grid>
        <Grid item xs={12} sm={4}></Grid>
      </Grid>
      <sub>{curTrackerId}</sub>
      
    </Paper>
  );
  
}

TrackerInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TrackerInfo);
