/**
 * 4 : DEBUG
 * 3 : INFO
 * 2 : WARN
 * 1 : ERROR
 */
var debugLevel = 4;

if (process.env.NODE_ENV === 'development') {
    debugLevel = 5;
} else {
    debugLevel = 1;
}

var debugLog = {
    debug: function(){},
    info: function(){},
    warn: function(){},
    error: function(){},
};

if (debugLevel >= 4) {
    debugLog.debug = console.debug.bind(window.console);
}

if (debugLog >= 3) {
    debugLog.info = console.info.bind(window.console);
}

if (debugLog >= 2) {
    debugLog.warn = console.warn.bind(window.console);
}

if (debugLevel >= 1) {
    debugLog.error = console.error.bind(window.console);
}

export default debugLog;