import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/styles/withStyles';
import { Field, Form } from 'react-final-form';
import debugLog from '../../services/debug/log';
import RFTextField from '../onepirate/modules/form/RFTextField';
import FormButton from '../onepirate/modules/form/FormButton';
import MenuItem from '@material-ui/core/MenuItem';
import RFSelect from '../onepirate/modules/form/RFSelect';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import NoTrackerSelected from '../dashboard/NoTrackerSelected';
import RFSwitch from '../onepirate/modules/form/RFSwitch';
import { WebWidgets } from '../webwidgets/WebWidgets';

const styles = (theme) => ({
  form: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

function UpdateTracker(props) {

  const { classes, curTrackerId, updateAsync, trackerinfo } = props;
  
  if (curTrackerId === null || 
    trackerinfo === null ||
    curTrackerId === undefined ||
    trackerinfo === undefined) {
      return (<NoTrackerSelected />);
  }

  let trackerConfig = trackerinfo === null ? {} : JSON.parse(trackerinfo.trackerConfig);

  const validate = (values) => {
    const errors = {};
    if (!values.trackerName) {
      errors.trackeName = "trackeName is Required";
    }
    return errors;
  };

  const onSubmit = async (values) => {
    debugLog.debug("UpdateTracker :: Form values : [%o]", values);
    updateAsync(values);
  };

  return (
    <Paper className={classes.paper}>
      <Form
        onSubmit={onSubmit}
        subscription={{ values: true, submitting: true, pristine: true }}
        validate={validate}
        initialValues={{
          "trackerId": curTrackerId,
          "trackerName": trackerinfo === null ? "" : trackerinfo.trackerName,
          "trackerType": trackerinfo === null ? "" : trackerinfo.trackerType,
          "trackerConfig": trackerinfo === null ? "" : trackerinfo.trackerConfig,
          "color": trackerConfig.color,
          "backgroundImage": trackerConfig.backgroundImage,
          "shape": trackerConfig.shape,
          "tooltip": trackerConfig.tooltip,
          "anchorid": trackerConfig.anchorid,
          "deploy": trackerConfig.deploy === undefined ? false : trackerConfig.deploy,
          "customCode":  trackerConfig.onClick,
        }}
        render={
          ({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className={classes.form} noValidate>

                <InputLabel id="tracker-type-label-id">Deploy</InputLabel>
                <Field 
                  autoComplete="deploy"
                  type="checkbox"
                  component={RFSwitch}
                  disabled={submitting}
                  fullWidth
                  label="Deploy"
                  margin="normal"
                  name="deploy"
                  required
                  size="large"
                />

                <Field
                  autoComplete="trackername"
                  autoFocus
                  component={RFTextField}
                  disabled={submitting}
                  fullWidth
                  label="Feeder Name"
                  margin="normal"
                  name="trackerName"
                  required
                  size="large"
                />

                <InputLabel id="tracker-type-label-id">Feeder Type</InputLabel>
                <Field 
                  labelId="tracker-type-label-id"
                  autoComplete="trackertype"
                  component={RFSelect}
                  fullWidth
                  label="Feeder Type"
                  name="trackerType"
                  required
                  disabled={submitting}
                  size="large"
                  margin="normal"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Fab'}>Fab</MenuItem>
                  <MenuItem value={'FiveStar'}>FiveStar</MenuItem>
                  <MenuItem value={'LikeDislike'}>LikeDislike</MenuItem>
                </Field>
                <br />
                <br />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={RFTextField}
                      autoComplete="color"
                      fullWidth
                      label="Color"
                      name="color"
                      disabled={submitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={RFTextField}
                      autoComplete="backgroundimage"
                      fullWidth
                      label="Background Image"
                      name="backgroundImage"
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Field
                      component={RFTextField}
                      autoComplete="shape"
                      fullWidth
                      label="Shape"
                      name="shape"
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      component={RFTextField}
                      autoComplete="tooltip"
                      fullWidth
                      label="Tooltip"
                      name="tooltip"
                      disabled={submitting}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={RFTextField}
                      autoComplete="anchorid"
                      fullWidth
                      label="anchorid"
                      name="anchorid"
                      disabled={submitting}
                    />
                  </Grid>

                </Grid>

                <Field
                  autoComplete="Custom Code"
                  component={RFTextField}
                  fullWidth
                  label="Custom Code OnClick"
                  margin="normal"
                  name="customCode"
                  size="large"
                  multiline
                  maxRows={10}
                  disabled
                />

                <FormButton
                    className={classes.button}
                    disabled={submitting}
                    size="large"
                    color="secondary"
                    fullWidth
                >
                {submitting ? 'In progress…' : 'Update'}
              </FormButton>
              <WebWidgets values={values}></WebWidgets>
            </form>
          )}
      />
    </Paper>
  );
}

UpdateTracker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UpdateTracker);
