import {  createEntityAdapter,  createSlice } from '@reduxjs/toolkit';

const trackerEntityAdapter = createEntityAdapter({
    selectId: (tracker) => tracker.trackerId,
    sortComparer: (a, b) => a.trackerName.localeCompare(b.trackerName),
});

export const trackerEntitySlice = createSlice({
    name: 'trackerentity',
    initialState: trackerEntityAdapter.getInitialState({}),
    reducers: {
        trackerAdded: trackerEntityAdapter.addOne,
        trackerDeleted: trackerEntityAdapter.removeOne,
        trackerDeletedMany: trackerEntityAdapter.removeMany,
        trackerUpdate: trackerEntityAdapter.updateOne,
        trackerFetched: trackerEntityAdapter.setOne,
        trackerFetchedMany: trackerEntityAdapter.setMany
    }
});

const trackerEntitySelectors = trackerEntityAdapter.getSelectors((state) => state.trackerentity);
export const selectTrackerEntityCount = (state) => trackerEntitySelectors.selectTotal(state);
export const selectTrackerEntityById = (state, id) => trackerEntitySelectors.selectById(state, id);
export const selectTrackerEntityAll = (state) => trackerEntitySelectors.selectAll(state);

export const { trackerAdded, trackerDeleted, trackerDeletedMany, trackerUpdate, trackerFetched, trackerFetchedMany }  = trackerEntitySlice.actions;

export default trackerEntitySlice.reducer;