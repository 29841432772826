import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './views/Home';
import SingIn from './views/SignIn';
import SignUp from './views/SignUp';
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import ForgotPassword from './views/ForgotPassword';
import Dashboard from './views/Dashboard';

import './App.css';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from './features/auth/authSlice';

function App() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return (
    <Router>
        <Switch>
          <Route path="/about">
            <div>About</div>
          </Route>
          <Route path="/user/signin">
              <SingIn></SingIn>
          </Route>
          <Route path="/user/signup">
              <SignUp></SignUp>
          </Route>
          <Route path="/user/forgotpass">
              <ForgotPassword></ForgotPassword>
          </Route>
          <Route path="/terms">
            <Terms></Terms>
          </Route>
          <Route path="/privacy">
            <Privacy></Privacy>
          </Route>
          <Route path="/">
            {
              isLoggedIn ?
              <Dashboard></Dashboard> :
              <Home></Home>
            }
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
