import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    categories: [
        {
          id: 'Account',
          children: [
            { id: 'User Profile', tabs: ['Profile', 'Billing', 'Activity']},
          ],
        },
        {
          id: 'Feeder',
          children: [
            { id: 'Explore', tabs: ['List', 'Add', 'Current', 'Edit']},
            { id: 'Pings', tabs: ['All', 'Single'] },
            { id: 'Reports', tabs: ['Summary', 'Search'] },
          ],
        },
      ],
    // Navigator
    activeCatId: 'Feeder',
    activeChildId: 'Explore',
    // Header
    activeTabItem: 'List'
    // Content
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        changeActiveChildId: (state, action) => {
            state.activeChildId = action.payload.childId;
            state.activeCatId = action.payload.catId;
            state.categories.map(({id, children}) => {
              children.map(({id: childId, tabs}) => {
                if (id === action.payload.catId && childId === action.payload.childId) {
                  state.activeTabItem = tabs[0];
                }
                return 0;
              })
              return 0;
            })
        },
        changeActiveTabItem: (state, action) => {
            state.activeTabItem = action.payload;
        },
        changeTab: (state, action) => {
          let { catId, childId, tabItem } = action.payload;
          state.activeCatId = catId;
          state.activeChildId = childId;
          state.activeTabItem = tabItem;
        }
    },
})

export const { changeActiveChildId, changeActiveTabItem, changeTab } = dashboardSlice.actions;
export default dashboardSlice.reducer;