import { connect } from 'react-redux';
import ProfileMenu from '../../../components/dashboard/ProfileMenu';
import { logOut } from '../../../services/localstorage/Auth';
import { changeTab } from '../dashboardSlice';

const mapStateToProps = (state, ownProps) => ({

})

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => logOut(),
    changeTabProfile: () => {
        dispatch(changeTab({
            catId: 'Account',
            childId: 'User Profile',
            tabItem: 'Profile'
        }));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);