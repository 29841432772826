import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import { Field, Form } from 'react-final-form';
import RFTextField from '../onepirate/modules/form/RFTextField';
import { joinWaitlist } from '../../services/api/workers';
import Snackbar from '../onepirate/modules/components/Snackbar';
import FormButton from '../onepirate/modules/form/FormButton';


const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: 'flex',
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.warning.main,
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
    marginTop: theme.spacing(1),
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
  },
  
});

function JoinWaitlist(props) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);

  const validate = (values) => {
    const errors = {};
    
    if (!values.email) {
      errors.email = "Email is Required";
    }
    
   return errors;
  };

  const handleSubmit = async (values) => {
    await joinWaitlist(values);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <Form
        onSubmit={handleSubmit}
        initialValues={{
        "email": "Your Email"
        }}
        validate={validate}
        subscription={{ submitting: true, pristine: true }}
        render={
        ({ handleSubmit, form, submitting, pristine, values }) => (
            <>
            <form onSubmit={handleSubmit} className={classes.cardContent} noValidate>
            <Field
                autoComplete="email"
                component={RFTextField}
                disabled={submitting}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
            />
            <FormButton
                className={classes.button}
                disabled={submitting || pristine}
                color="secondary"
                fullWidth
            >
                {submitting ? 'In progress…' : 'Join The Waitlist'}
            </FormButton>
            </form>
            </>
        )
        }
        />

      <Snackbar
        open={open}
        onClose={handleClose}
        message="Thanks For Joining the Waitlist !"
      />
    </>
  );
}

JoinWaitlist.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JoinWaitlist);
