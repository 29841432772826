import withRoot from '../app/withRoot.js';
import React from 'react';
import Paperbase from '../components/paperbase/Paperbase';


/**
 * The Dashboard view
 * Currently Using Paperbase
 * 
 * Not Connected with Redux: (stateless)
 * Applies global theme to the underlying dashboard: (withRoot)
 * Adjust theme based on global theme: (withStyles)
 * @returns 
 */
function Index() {
  return (
    <React.Fragment>
      <Paperbase />
    </React.Fragment>
  );
}

export default withRoot(Index);