export function setAccessToken(accessToken) {
    localStorage.setItem('accessToken', accessToken);
}

export function getAccessToken() {
    return localStorage.getItem('accessToken');
}

export function setRefreshToken(refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
}

export function getRefreshToken() {
    return localStorage.getItem('refreshToken');
}

export function setNextRefresh(timestamp) {
    localStorage.setItem('nextRefresh', timestamp);
}

export function getNextRefresh() {
    let nextRefresh = localStorage.getItem('nextRefresh');
    if (nextRefresh !== undefined && nextRefresh !== null) {
        nextRefresh = parseInt(nextRefresh);
    } else {
        nextRefresh = 0;
    }
    return nextRefresh;
}

export function logOut() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('nextRefresh');
    window.location = '/';
}