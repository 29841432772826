import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import { Link as HistoryLink } from "react-router-dom";
import { USER_TAP_LANDING_PAGE } from '../../../../app/strings';

const styles = (theme) => ({
  background: {
    background: `linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%)`,
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      <Typography color="inherit" align="center" variant="h2" marked="center">
      {USER_TAP_LANDING_PAGE.tagLine}
      </Typography>
      <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
      {USER_TAP_LANDING_PAGE.smallDescription}
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        component={HistoryLink}
        to="/user/signup"
      >
        Register
      </Button>
      <Typography variant="body2" color="inherit" className={classes.more}>
        {USER_TAP_LANDING_PAGE.betaOffer}
      </Typography>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
