import { connect } from 'react-redux';
import ListTrackers from '../../../components/tracker/ListTrackers';
import { changeTab } from '../../dashboard/dashboardSlice';
import { listTrackersAsync } from '../trackerApiSlice';
import { selectTrackerEntityAll } from '../trackerEntitySlice';
import { setCurTracker } from '../trackerSlice';

const mapStateToProps = (state, ownProps) => ({
    trackerIdsLoading: state.trackerapi.listTrackersAsyncLoading,
    trackerIds: selectTrackerEntityAll(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    allTrackersAsync: () => dispatch(listTrackersAsync()),
    onClickTracker: function (trackerId) {
        dispatch(setCurTracker(trackerId));
        dispatch(changeTab({
            catId: 'Feeder',
            childId: 'Pings',
            tabItem: 'Single'
        }));
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ListTrackers);