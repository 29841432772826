import React from 'react';
import { FabWebWidget } from './fab/Fab';
import { TooltipWebWidget } from './tooltip/Tooltip';
import { OverlayWebWidget } from './overlay/Overlay';
import { SidepanelWebWidget } from './sidepanel/Sidepanel';
import { FiveStarWebWidget } from './fivestar/FiveStar';
import { LikeDislikeWebWidget } from './likedislike/LikeDislike';

export function WebWidgets(props) {

    switch(props.values.trackerType) {
        case 'Fab':
            return (<FabWebWidget tc={JSON.stringify(props.values)}></FabWebWidget>);
        case 'Overlay':
            return (<OverlayWebWidget tc={JSON.stringify(props.values)}></OverlayWebWidget>);
        case 'SidePanel':
            return (<SidepanelWebWidget tc={JSON.stringify(props.values)}></SidepanelWebWidget>);
        case 'ToolTip':
            return (<TooltipWebWidget tc={JSON.stringify(props.values)}></TooltipWebWidget>);
        case 'FiveStar':
            return (<FiveStarWebWidget tc={JSON.stringify(props.values)}></FiveStarWebWidget>);
        case 'LikeDislike':
            return (<LikeDislikeWebWidget tc={JSON.stringify(props.values)}></LikeDislikeWebWidget>);
        default:
            return (<></>);
    }
  }
  