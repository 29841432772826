import { connect } from 'react-redux';
import Content from '../../../components/paperbase/Content';

const mapStateToProps = (state, ownProps) => ({
    categories: state.dashboard.categories,
    activeCatId: state.dashboard.activeCatId,
    activeChildId: state.dashboard.activeChildId,
    activeTabItem: state.dashboard.activeTabItem
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Content);