import debugLog from '../debug/log';

export async function joinWaitlist(data = {}) {
    // Default options are marked with *
    let url = 'https://waitlist-handler.makeall.workers.dev/submit';
    let formData = new FormData();
    formData.append("email", data.email);
   
    debugLog.debug("joinWaitlist:: data : [%o]", data);
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: formData // body data type must match "Content-Type" header
    });
    debugLog.debug("joinWaitlist:: response : [%o]", response);
    return;
}