import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { USER_TAP_LIB_LOCATION } from '../../app/strings';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import { Doughnut, Bar, Pie } from 'react-chartjs-2';
import NoTrackerSelected from '../dashboard/NoTrackerSelected';
import { flatObject } from '../../services/helpers/flatten';
import { getBrowserName, getOsName } from '../../services/helpers/clientinfo';

const rand = () => Math.round(Math.random() * 20 - 10);

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    minHeight: '150px'
  }));

const styles = (theme) => ({
  form: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
    padding: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

function getPingDataPoints(tpings) {
    let data = [];
    tpings.forEach(element => {
      flatObject(JSON.parse(element.trackerData));
    });
    tpings.map(({pingTime}) => data.push(
        {
            x: rand(),
            y: rand()
        }
    ));
    return data;
}

function ReportTracker(props) {

  const { curTrackerId, trackerinfo, trackerPings } = props;

  if (curTrackerId === null || 
    trackerinfo === null ||
    curTrackerId === undefined ||
    trackerinfo === undefined) {
      return (<NoTrackerSelected />);
  }
  
  let trackerConfig = JSON.parse(trackerinfo.trackerConfig);

  let pingDataFlat = [];
  trackerPings.forEach((element) => {
    pingDataFlat.push(flatObject({
      id: element.id,
      pingTime: element.pingTime,
      trackerId: element.trackerId,
      trackerData: JSON.parse(element.trackerData)
    }))
  });

  return (

    <Grid container spacing={2}>
      
    <Grid item xs={4}>
        <Item>
        <h3>{trackerinfo.trackerName}</h3>
        <p>Tracker Type: <b>{trackerinfo.trackerType}</b></p>
        <sub>{curTrackerId}</sub>
        </Item>
    </Grid>
    
    <Grid item xs={4}>
        <Item>
        <h3>Config</h3>
        <p>Color: <b>{trackerConfig.color}</b></p>
        <p>Tooltip: <b>{trackerConfig.tooltip}</b></p>
        </Item>
    </Grid>

    <Grid item xs={4}>
        <Item>
        <h3>Pings</h3>
        <p>Total Synced: <b>{trackerPings.length}</b></p>
        </Item>
    </Grid>

    <Grid item xs={8}>
        <Item>
            <PingFrequencyPlot pingData={pingDataFlat} />
        </Item>
    </Grid>
    <Grid item xs={4}>
        <Item>
          <PingPlatformPlot pingData={pingDataFlat} />
        </Item>
    </Grid>

    <Grid item xs={4}>
        <Item>
            <PingBrowserPlot pingData={pingDataFlat} />
        </Item>
    </Grid>
    <Grid item xs={8}>
        <Item>
          <PingFrequencyTimeZonePlot pingData={pingDataFlat} />
        </Item>
    </Grid>
    </Grid>

  );
}

ReportTracker.propTypes = {
  classes: PropTypes.object.isRequired,
};


function PingFrequencyPlot(props) {
  const { pingData } = props;

  // pingData.sort(function(a, b) {
  //   var keyA = new Date(a.pingTime), keyB = new Date(b.pingTime);
  //   if (keyA < keyB) return -1;
  //   if (keyA > keyB) return 1;
  //   return 0;
  // });

  let frequencyMap = {};
  pingData.forEach(element => {
    let d = (new Date(element.pingTime)).toDateString();
    if (d in frequencyMap) {
      frequencyMap[d] += 1;
    } else {
      frequencyMap[d] = 1;
    }
  })

  let labels = Object.keys(frequencyMap)
  let freqs = [];
  labels.forEach(l => {
    freqs.push(frequencyMap[l]);
  })

  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of Pings',
        data: freqs,
      }
    ]
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
      
  return (
    <Bar data={data} options={options} />
  )
}


function PingPlatformPlot(props) {
  const { pingData } = props;

  let frequencyMap = {};
  pingData.forEach(element => {
    let d = getOsName(element["trackerData.navigator.userAgent"]);
    if (d in frequencyMap) {
      frequencyMap[d] += 1;
    } else {
      frequencyMap[d] = 1;
    }
  })

  let labels = Object.keys(frequencyMap)
  let freqs = [];
  labels.forEach(l => {
    freqs.push(frequencyMap[l]);
  })

  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of Pings',
        data: freqs,
      }
    ]
  };
      
  return (
    <Doughnut data={data} />
  )
}

function PingBrowserPlot(props) {
  
  const { pingData } = props;

  let frequencyMap = {};
  pingData.forEach(element => {
    let d = getBrowserName(element["trackerData.navigator.userAgent"]);
    if (d in frequencyMap) {
      frequencyMap[d] += 1;
    } else {
      frequencyMap[d] = 1;
    }
  })

  let labels = Object.keys(frequencyMap)
  let freqs = [];
  labels.forEach(l => {
    freqs.push(frequencyMap[l]);
  })

  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of Pings',
        data: freqs,
      }
    ]
  };
      
  return (
    <Pie data={data} />
  )
}


function PingFrequencyTimeZonePlot(props) {
  const { pingData } = props;


  let frequencyMap = {};
  pingData.forEach(element => {
    let d = `GMT-${element["trackerData.timezone"]}`;
    if (d in frequencyMap) {
      frequencyMap[d] += 1;
    } else {
      frequencyMap[d] = 1;
    }
  })

  let labels = Object.keys(frequencyMap)
  let freqs = [];
  labels.forEach(l => {
    freqs.push(frequencyMap[l]);
  })

  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of Pings',
        data: freqs,
      }
    ]
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
      
  return (
    <Bar data={data} options={options} />
  )
}

export default withStyles(styles)(ReportTracker);
