import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import logger from 'redux-logger';
import { crashReporter } from '../middlewares/CrashReporterMiddleware';
import { refreshTokenMiddleware } from '../middlewares/TokenRefresh';
import trackerReducer from '../features/tracker/trackerSlice';
import trackerApiReducer from '../features/tracker/trackerApiSlice';
import trackerEntityReducer from '../features/tracker/trackerEntitySlice';
import trackerPingApiReducer from '../features/tracker/trackerPingApiSlice';
import trackerPingEntityReducer from '../features/tracker/trackerPingEntitySlice';
import userApiReducer from '../features/auth/userApiSlice';
import userEntityReducer from '../features/auth/userEntitySlice';

let customMiddlewares = null;

if (process.env.NODE_ENV === 'development') {
  customMiddlewares = [refreshTokenMiddleware, crashReporter, logger];
} else {
  customMiddlewares = [refreshTokenMiddleware, crashReporter];
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    tracker: trackerReducer,
    trackerapi: trackerApiReducer,
    trackerentity: trackerEntityReducer,
    trackerpingapi: trackerPingApiReducer,
    trackerpingentity: trackerPingEntityReducer,
    userapi: userApiReducer,
    userentity: userEntityReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(customMiddlewares),
});
