import { connect } from 'react-redux';
import TrackerInfo from '../../../components/tracker/TrackerInfo';
import { selectTrackerEntityById } from '../trackerEntitySlice';

const mapStateToProps = (state, ownProps) => ({
    curTrackerId: state.tracker.curTrackerId,
    trackerinfo: selectTrackerEntityById(state, state.tracker.curTrackerId)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(TrackerInfo);