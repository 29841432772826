import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserInfo } from '../../services/api/User';
import { setUserId } from './authSlice';
import { userFetched } from './userEntitySlice';


const initialState = {
    // getUserAsync
    getUserAsyncLoading: false
}

export const getUserAsync = createAsyncThunk(
    'trackerpingapi/listtrackerpings',
    async(values, { dispatch }) => {
        try {
            getUserInfo()
            .then( responseJson => {
                dispatch(userFetched(responseJson));
                dispatch(setUserId(responseJson.userId));
                return Promise.resolve(responseJson);
            })
            .catch( errText => {
                return Promise.reject(errText);
            })
        } catch (err) {
            return err;
        }
    }
)

export const userApiSlice = createSlice({
    name: 'userapi',
    initialState: initialState,
    extraReducers: (builder) => {
        builder
        .addCase(getUserAsync.pending, (state) => {
            state.getUserAsyncLoading = true;
        })
        .addCase(getUserAsync.fulfilled, (state, action) => {
            state.getUserAsyncLoading = false;
        })
        .addCase(getUserAsync.rejected, (state, action) => {
            state.getUserAsyncLoading = false;
        })
    }
});

export default userApiSlice.reducer;