


import React, { useEffect } from 'react';
import './LikeDislike.css';
import '../WebWidgets.css';

export function LikeDislikeWebWidget(props) {

    let trackerConfig = JSON.parse(props.tc);
    
    useEffect(() => {
        let textElem = document.getElementsByClassName('devmakeall-usertap-likedislike-text')[0];
        textElem.innerHTML = trackerConfig.tooltip;
        let unicodeElem = document.getElementsByClassName('devmakeall-usertap-likedislike-one')[0];
        unicodeElem.style.textShadow = `0 0 0 ${trackerConfig.color}`;

        unicodeElem = document.getElementsByClassName('devmakeall-usertap-likedislike-two')[0];
        unicodeElem.style.textShadow = `0 0 0 ${trackerConfig.color}`;
    });

    return (
        <>
        <div class="devmakell-usertap-webwidgets-wrapper">
        <div class="devmakeall-usertap-likedislike-panel">
            <div class="devmakeall-usertap-likedislike">
                <span class="devmakeall-usertap-likedislike-one">👍</span>
                <span class="devmakeall-usertap-likedislike-two">👎</span>
                <span class="devmakeall-usertap-likedislike-text">Rate Your Experience</span>
            </div>
        </div>
        </div>
        </>
    );
}