import { USER_TAP_LIB_LOCATION } from "../../app/strings";

export function getDeployCode(userId) {
    return `<script>
    (function (w,d,s,o,f,js,fjs) {
        w['devmakeall-UserTap-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
        js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
        js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', 'glw', ${USER_TAP_LIB_LOCATION}));
    glw('deploy', ${userId});
</script>`;
}