import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@material-ui/styles/withStyles';
import Container from '@material-ui/core/Container';

import './ProductHeroLayout.css';
import { USER_TAP_LANDING_BUBBLES } from '../../../../app/strings';

const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '80vh',
      minHeight: 500,
      maxHeight: 1300,
    },
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.5,
    zIndex: -1,
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
  },
  arrowDown: {
    position: 'absolute',
    bottom: theme.spacing(4),
  },
  waves: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '15vh',
    marginBottom: '-7px', /*Fix for safari gap*/
    minHeight: '100px',
    maxHeight:'150px',
  }
});

function ProductHeroLayout(props) {
  const { backgroundClassName, children, classes } = props;

  return (
    <>
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/static/img/productHeroWonder.png"
          alt="wonder"
          width="147"
          height="80"
        />
        {children}

        <div className={clsx(classes.background, backgroundClassName)} />
        <img
          className={classes.arrowDown}
          src="/static/img/productHeroArrowDown.png"
          height="16"
          width="12"
          alt="arrow down"
        />
        
        <div className={classes.backdrop} >
        <div id="background-wrap">
          <div class="bubble x1" data-tagline={USER_TAP_LANDING_BUBBLES[0]}></div>
          <div class="bubble x2" data-tagline={USER_TAP_LANDING_BUBBLES[1]}></div>
          <div class="bubble x3" data-tagline={USER_TAP_LANDING_BUBBLES[2]}></div>
          <div class="bubble x4" data-tagline={USER_TAP_LANDING_BUBBLES[3]}></div>
          <div class="bubble x5" data-tagline={USER_TAP_LANDING_BUBBLES[4]}></div>
          <div class="bubble x6" data-tagline={USER_TAP_LANDING_BUBBLES[5]}></div>
          <div class="bubble x7" data-tagline={USER_TAP_LANDING_BUBBLES[6]}></div>
          <div class="bubble x8" data-tagline={USER_TAP_LANDING_BUBBLES[7]}></div>
          <div class="bubble x9" data-tagline={USER_TAP_LANDING_BUBBLES[8]}></div>
          <div class="bubble x10" data-tagline={USER_TAP_LANDING_BUBBLES[9]}></div>
      </div>
          <div>
            <svg className={classes.waves} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
              <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g class="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
              </g>
            </svg>
          </div>
        </div>
      </Container>
      
    </section>
    
    </>
  );
}

ProductHeroLayout.propTypes = {
  backgroundClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHeroLayout);
