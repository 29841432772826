import { connect } from 'react-redux';
import Navigator from '../../../components/paperbase/Navigator';
import { changeActiveChildId } from '../dashboardSlice';

const mapStateToProps = (state, ownProps) => ({
    categories: state.dashboard.categories,
    activechildid: state.dashboard.activeChildId,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeactivechildid: (childId) => dispatch(changeActiveChildId(childId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);