import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import LoadingScreen from './LoadingScreen';
import { getDeployCode } from '../../services/helpers/embedcode';
import { TextField } from '@material-ui/core';


const styles = (theme) => ({
  form: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
    padding: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

function UserInfo(props) {
  const { classes, curUserId, userInfo, getUserInfo, userInfoLoading } = props;

  useEffect(() => {
    if (curUserId === null && !userInfoLoading) {
      getUserInfo();
    }
  });

  if (userInfoLoading ||
    curUserId === null ||
    userInfo === null ||
    userInfo === undefined) {
    return (
      <LoadingScreen />
    );
  }
  
  return (
    <Paper className={classes.paper}>
      
      <h3>{userInfo.userName}</h3>
      <p>User Type: <b>{userInfo.userType}</b></p>
      
      <Grid container spacing={1} border={1} borderRadius={1} borderColor="secondary.main">
        <Grid item xs={12} sm={4}>Authenticated</Grid>
        <Grid item xs={12} sm={4}>{userInfo.authenticated}</Grid>
        <Grid item xs={12} sm={4}></Grid>
      </Grid>
      <sub>{curUserId}</sub>
      <br />
      <br />
      <br />
      <br />
      <TextField 
          disabled 
          label="Embed"
          multiline
          rows={8}
          defaultValue={getDeployCode(curUserId)}
          fullWidth
          variant="outlined"
      />
    </Paper>
  );
  
}

UserInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserInfo);
