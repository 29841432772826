import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { create, update, deleteTrackers, listTrackers, getTracker } from '../../services/api/Tracker';
import { changeActiveTabItem } from '../dashboard/dashboardSlice';
import { trackerAdded, trackerDeletedMany, trackerFetched, trackerFetchedMany, trackerUpdate } from './trackerEntitySlice';
import { setCurTracker } from './trackerSlice';

const initialState = {
    // createAsync
    createAsyncLoading: false,
    // updateAsync
    updateAsyncLoading: false,
    // listTrackersAsync
    listTrackersAsyncLoading: false,
    // deleteAsync
    deleteAsyncLoading: false,
    // getTrackerAsync
    getTrackerAsyncLoading: false,
}

export const createAsync = createAsyncThunk(
    'trackerapi/create',
    async(values, { dispatch }) => {
        try {
            create(
                values.trackerName,
                values.trackerType,
                JSON.stringify({
                    "color": values.color,
                    "shape": values.shape,
                    "backgroundImage": values.backgroundImage,
                    "tooltip": values.tooltip,
                    "onClick": values.customCode,
                    "anchorid": values.anchorid,
                    "debug": false
                })
            )
            .then(responseJson => {
                dispatch(trackerAdded(responseJson));
                dispatch(setCurTracker(responseJson.trackerId));
                dispatch(changeActiveTabItem("Current"));
                return Promise.resolve(responseJson);
            })
            .catch( errText => {
                return Promise.reject(errText);
            })
        } catch (err) {
            return err;
        }
    }
)

export const updateAsync = createAsyncThunk(
    'trackerapi/update',
    async(values, { dispatch }) => {
        try {
            update(
                values.trackerId,
                values.trackerName,
                values.trackerType,
                JSON.stringify({
                    "color": values.color,
                    "shape": values.shape,
                    "backgroundImage": values.backgroundImage,
                    "tooltip": values.tooltip,
                    "onClick": values.customCode,
                    "anchorid": values.anchorid,
                    "deploy": values.deploy
                })
            )
            .then( responseJson => {
                dispatch(trackerUpdate(responseJson));
                dispatch(setCurTracker(responseJson.trackerId));
                dispatch(changeActiveTabItem("Current"));
                return Promise.resolve(responseJson);
            })
            .catch( errText => {
                return Promise.reject(errText);
            })
        } catch (err) {
            return err;
        }
    }
)

export const deleteAsync = createAsyncThunk(
    'trackerapi/delete',
    async(values, { dispatch }) => {
        try {
            deleteTrackers(values)
            .then( responseJson => {
                dispatch(trackerDeletedMany(values));
                return Promise.resolve(responseJson);
            })
            .catch( errText => {
                return Promise.reject(errText);
            })
        } catch (err) {
            return err;
        }
    }
)

export const listTrackersAsync = createAsyncThunk(
    'trackerapi/listtrackers',
    async(values, { dispatch }) => {
        try {
            listTrackers()
            .then( responseJson => {
                dispatch(trackerFetchedMany(responseJson));
                if (responseJson instanceof Array && responseJson.length > 0) {
                    dispatch(setCurTracker(responseJson[0].trackerId));
                }
                return Promise.resolve(responseJson);
            })
            .catch( errText => {
                return Promise.reject(errText);
            })
        } catch (err) {
            return err;
        }
    }
)

export const getTrackerAsync = createAsyncThunk(
    'trackerapi/gettracker',
    async(values, { dispatch }) => {
        try {
            getTracker(values.trackerId)
            .then( responseJson => {
                dispatch(trackerFetched(responseJson));
                return Promise.resolve(responseJson);
            })
            .catch( errText => {
                return Promise.reject(errText);
            })
        } catch (err) {
            return err;
        }
    }
)

export const trackerApiSlice = createSlice({
    name: 'trackerapi',
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(createAsync.pending, (state) => {
            state.createAsyncLoading = true;
        })
        .addCase(createAsync.fulfilled, (state, action) => {
            state.createAsyncLoading = false;
        })
        .addCase(updateAsync.pending, (state) => {
            state.updateAsyncLoading = true;
        })
        .addCase(updateAsync.fulfilled, (state, action) => {
            state.updateAsyncLoading = false;
        })
        .addCase(listTrackersAsync.pending, (state) => {
            state.listTrackersAsyncLoading = true;
        })
        .addCase(listTrackersAsync.fulfilled, (state, action) => {
            state.listTrackersAsyncLoading = false;
        })
        .addCase(getTrackerAsync.pending, (state) => {
            state.getTrackerAsyncLoading = true;
        })
        .addCase(getTrackerAsync.fulfilled, (state, action) => {
            state.getTrackerAsyncLoading = false;
        })
        .addCase(deleteAsync.pending, (state) => {
            state.deleteAsyncLoading = true;
        })
        .addCase(deleteAsync.fulfilled, (state, action) => {
            state.deleteAsyncLoading = false;
        })
    }
})

export default trackerApiSlice.reducer;