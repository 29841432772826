import { connect } from 'react-redux';
import AddTracker from '../../../components/tracker/AddTracker';
import { createAsync } from '../trackerApiSlice';

const mapStateToProps = (state, ownProps) => ({

})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createAsync: (values) => dispatch(createAsync(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddTracker);