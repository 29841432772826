import { getAccessToken } from '../localstorage/Auth';
import debugLog from '../debug/log';

export async function postData(url = '', data = {}) {
    // Default options are marked with *
    let accessToken = getAccessToken();
    let headers = {
      'Content-Type': 'application/json'
    }
    if (accessToken !== undefined && accessToken !== null) {
      headers['X-CB-API-KEY'] = accessToken;
    }
    debugLog.debug("postData :: Url: [%s], data [%o]", url, data);

    let response = null;
    try {
        response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: headers,
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
    } catch (err) {
      debugLog.debug("postData :: %o", err);
      return Promise.reject({
        "error": true,
        "errorMessage": err
      });
    }
    debugLog.debug("postData:: response : [%o]", response);
    if (response.status <= 299 && response.status >= 200) {
      debugLog.debug("postData :: request succeded url: [%s]", url);
      return response.json();
    } else {
      debugLog.debug("postData :: request failed url: [%s]", url);
      return Promise.reject({
        "error": true,
        "errorMessage": response.statusText
      });
    }
}


export async function putData(url = '', data = {}) {
  // Default options are marked with *
  let accessToken = getAccessToken();
  let headers = {
    'Content-Type': 'application/json'
  }
  if (accessToken !== undefined && accessToken !== null) {
    headers['X-CB-API-KEY'] = accessToken;
  }
  debugLog.debug("putData :: Url: [%s], data [%o]", url, data);
  const response = await fetch(url, {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  debugLog.debug("putData:: response : [%o]", response);
  if (response.status <= 299 && response.status >= 200) {
    debugLog.debug("putData :: request succeded url: [%s]", url);
    return response.json();
  } else {
    debugLog.debug("putData :: request failed url: [%s]", url);
    return Promise.reject({
      "error": true,
      "errorMessage": response.statusText
    });
  }
}

export async function getData(url = '') {
    debugLog.debug("getData :: Url: [%s]", url);
    let accessToken = getAccessToken();
    let headers = {
      'Content-Type': 'application/json'
    }
    if (accessToken !== undefined && accessToken !== null) {
      headers['X-CB-API-KEY'] = accessToken;
    }
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    // parses JSON response into native JavaScript objects
    if (response.status <= 299 && response.status >= 200) {
      debugLog.debug("getData :: request succeded url: [%s]", url);
      return response.json(); // parses JSON response into native JavaScript objects
    } else {
      debugLog.debug("getData :: request failed url: [%s]", url);
      return Promise.reject(response.statusText);
    }
}

export async function deleteData(url = '') {
  debugLog.debug("deleteData :: Url: [%s]", url);
  let accessToken = getAccessToken();
  let headers = {
    'Content-Type': 'application/json'
  }
  if (accessToken !== undefined && accessToken !== null) {
    headers['X-CB-API-KEY'] = accessToken;
  }
  
  const response = await fetch(url, {
    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  // parses JSON response into native JavaScript objects
  if (response.status <= 299 && response.status >= 200) {
    debugLog.debug("deleteData :: request succeded url: [%s]", url);
    return Promise.resolve({});//response.json(); // parses JSON response into native JavaScript objects
  } else {
    debugLog.debug("deleteData :: request failed url: [%s]", url);
    return Promise.reject(response.statusText);
  }
}