
// Brand strings
export const USER_TAP_APP_NAME = 'gleaner.';
export const USER_TAP_DOMAIN = 'gleaner.in';
export const USER_TAP_LANDING_PAGE = {
    tagLine: 'Frictionless User Feedback',
    smallDescription: 'Know what your user think about your product and features',
    betaOffer: 'Free for Beta Customers'

}
export const USER_TAP_TAGLINES_ALT = [
    'Frictionless User Feedback',
    'Get User Feedback Instantly',
    'Easiest way to get user feedback',
    'Survey Forms Reimagined',
    'One touch user feedback',
];
export const USER_TAP_LANDING_BUBBLES = [
    'No PopUps',
    'No Interruptions',
    'Breezy',
    'Seamless',
    'Embedded',
    '♥',
    'Customisable',
    'Easy',
    'Cool',
    'Modern',
    ''
]

//export const USER_TAP_LIB_LOCATION = 'http://localhost:8080/lib/widget.js';
export const USER_TAP_LIB_LOCATION = 'https://gleaner.in/lib/widget.js';