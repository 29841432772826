import React, { useEffect } from 'react';
import './Tooltip.css';

export function TooltipWebWidget(props) {

    let trackerConfig = JSON.parse(props.tc);
    
    useEffect(() => {
        document.getElementById('tooltippreview').style.background = trackerConfig.color;
        document.getElementById('tooltippreview').style.backgroundColor = trackerConfig.color;
        document.getElementById('tooltippreview').textContent = trackerConfig.tooltip;
    });

    return (
        <>
            <div class="devmakeall-usertap-tooltip">
                Target Element
                <div id="tooltippreview" class="devmakeall-usertap-tooltiptext">
                </div>
            </div>
        </>
    );
}