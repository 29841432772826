import { connect } from 'react-redux';
import ListTrackers from '../../../components/tracker/ListTrackers';
import { changeTab } from '../../dashboard/dashboardSlice';
import { deleteAsync, listTrackersAsync } from '../trackerApiSlice';
import { selectTrackerEntityAll } from '../trackerEntitySlice';
import { setCurTracker } from '../trackerSlice';

const mapStateToProps = (state, ownProps) => ({
    trackerIdsLoading: state.trackerapi.listTrackersAsyncLoading,
    trackerIds: selectTrackerEntityAll(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    allTrackersAsync: () => dispatch(listTrackersAsync()),
    onClickTracker: function (trackerId) {
        dispatch(setCurTracker(trackerId));
        dispatch(changeTab({
            catId: 'Feeder',
            childId: 'Explore',
            tabItem: 'Current'
        }));
    },
    onClickTrackerEdit: function (trackerId) {
        dispatch(setCurTracker(trackerId));
        dispatch(changeTab({
            catId: 'Feeder',
            childId: 'Explore',
            tabItem: 'Edit'
        }));
    },
    onDeleteTracker: function(trackerIds) {
        dispatch(deleteAsync(trackerIds));
        // dispatch(listTrackersAsync());
        // dispatch(changeTab({
        //     catId: 'Feeder',
        //     childId: 'Explore',
        //     tabItem: 'List'
        // }));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ListTrackers);