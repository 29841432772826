import { deleteData, getData, postData, putData } from "./Api";

const API_TRACKER_CREATE = '/api/tracker/';
const API_TRACKER_UPDATE = '/api/tracker/';
const API_TRACKER_LIST = '/api/tracker';

const API_TRACKER_PINGS = (trackerId) => `/api/tracker/${trackerId}/pings`
const API_TRACKER_GET = (trackerId) => `/api/tracker/${trackerId}`
const API_TRACKER_DELETE = (trackerId) => `/api/tracker/${trackerId}`;

export async function create(trackerName, trackerType, trackerConfig) {
    return postData(
        API_TRACKER_CREATE,
        {
            "trackerName": trackerName,
            "trackerType": trackerType,
            "trackerConfig": trackerConfig
        }
    );
}

export async function update(trackerId, trackerName, trackerType, trackerConfig) {
    return putData(
        API_TRACKER_UPDATE,
        {
            "trackerId": trackerId,
            "trackerName": trackerName,
            "trackerType": trackerType,
            "trackerConfig": trackerConfig
        }
    );
}


export async function listTrackers() {
    return getData(
        API_TRACKER_LIST
    );
}

export async function listTrackerPings(trackerId) {
    return getData(
        API_TRACKER_PINGS(trackerId)
    );
}

export async function getTracker(trackerId) {
    return getData(
        API_TRACKER_GET(trackerId)
    );
}

export async function deleteTrackers(trackerIds) {
    trackerIds.map(tid => deleteData(API_TRACKER_DELETE(tid)));
    return {};
}