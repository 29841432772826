import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import TrackerInfoConnected from '../../features/tracker/components/TrackerInfoConnected';
import { USER_TAP_LIB_LOCATION } from '../../app/strings';
import NoTrackerSelected from '../dashboard/NoTrackerSelected';

const styles = (theme) => ({
  form: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
    padding: 10
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

function getEmbedCode(trackerId) {
    return `
    <script>
        (function (w,d,s,o,f,js,fjs) {
            w['devmakeall-UserTap-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
            js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
            js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
        }(window, document, 'script', 'glw', '${USER_TAP_LIB_LOCATION}'));
        glw('launch', '${trackerId}');
    </script>
    `
}

function OneTracker(props) {
  const { classes, curTrackerId } = props;
  if (curTrackerId === null ||
    curTrackerId === undefined) {
      return (<NoTrackerSelected />);
  }
  return (
    <Paper className={classes.paper}>
      <TrackerInfoConnected />
      <br />
      <hr />
      <br />
      <TextField 
          disabled 
          id="tracker-embedd-code"
          label="Embed"
          multiline
          rows={12}
          defaultValue={getEmbedCode(curTrackerId)}
          fullWidth
          variant="outlined"
        />
    </Paper>
  );
}

OneTracker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OneTracker);
