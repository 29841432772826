import { connect } from 'react-redux';
import UserInfo from '../../../components/dashboard/UserInfo';
import { getUserAsync } from '../userApiSlice';
import { selectUserEntityById } from '../userEntitySlice';

const mapStateToProps = (state, ownProps) => ({
    curUserId: state.auth.curUserId,
    userInfo: state.auth.curUserId === null ? {} : selectUserEntityById(state, state.auth.curUserId),
    //userInfo: selectUserEntityCount(state) === 0 ? {} : selectUserEntityAll(state)[0],
    userInfoLoading: state.userapi.getUserAsyncLoading,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getUserInfo: () => {
        dispatch(getUserAsync());
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);