import { connect } from 'react-redux';
import AppAppBar from '../../../components/onepirate/modules/views/AppAppBar';

const mapStateToProps = (state, ownProps) => ({
    isLoggedIn: state.auth.isLoggedIn
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(AppAppBar);