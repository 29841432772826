import {  createEntityAdapter,  createSlice } from '@reduxjs/toolkit';

const trackerPingEntityAdapter = createEntityAdapter({
    selectId: (trackerPing) => trackerPing.id,
    sortComparer: (a, b) => a.pingTime.localeCompare(b.pingTime),
});

export const trackerPingEntitySlice = createSlice({
    name: 'trackerpingentity',
    initialState: trackerPingEntityAdapter.getInitialState({}),
    reducers: {
        trackerPingFetchedMany: trackerPingEntityAdapter.setMany
    }
});

const trackerPingEntitySelectors = trackerPingEntityAdapter.getSelectors((state) => state.trackerpingentity);
export const selectTrackerPingEntityAll = (state) => trackerPingEntitySelectors.selectAll(state);
export const selectTrackerPingEntityById = (state, id) => trackerPingEntitySelectors.selectById(state, id);

export const { trackerPingFetchedMany } = trackerPingEntitySlice.actions;

export default trackerPingEntitySlice.reducer;